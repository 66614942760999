import { useState } from 'react';
import axios from 'axios';
export default function Admin() {
    const [meterId, setMeterId] = useState('2-2-15075');
    const [meterReading, setMeterReading] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = {
            "id": meterId,
            "meterReading": parseInt(meterReading)
        }
        axios.put('http://192.168.0.163:4000/api/usage/create', formData).then(response => {
            console.log(response);
        });
    };
    return (
        <div>
            <form className="pt-4" onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group">
                    <label htmlFor="selectMeter">Select Meter</label>
                    <select value={meterId} onChange={e => setMeterId(e.target.value)} name="meterId" className="form-control" id="selectMeter">
                        <option value="2-2-15075">Room 1 Kitchen</option>
                        <option value="2-2-15124">Room 1 Bathroom</option>
                        <option value="2-2-15121">Room 2 Kitchen</option>
                        <option value="2-2-15468">Room 2 Bathroom</option>
                        <option value="2-2-15469">Room 3 Kitchen</option>
                        <option value="2-2-15471">Room 3 Bathroom</option>
                        <option value="2-2-15472">Room 4 Kitchen</option>
                        <option value="2-2-15707">Room 4 Bathroom</option>
                        <option value="2-2-15475">Room 5 Kitchen</option>
                        <option value="2-2-15476">Room 5 Bathroom</option>
                        <option value="2-2-15477">Room 6 Kitchen</option>
                        <option value="2-2-15499">Room 6 Bathroom</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="meterReadingId">Meter Reading</label>
                    <input value={meterReading} onChange={e => setMeterReading(e.target.value)} type="text" name="meterReading" className="form-control" id="meterReadingId" placeholder="Meter Reading" />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
}
