
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import Header from "./components/header/header";
import Sidemenu from "./components/sidemenu/sidemenu";
import Admin from "./components/admin/admin";
import 'bootstrap/dist/css/bootstrap.min.css';
import './vendor/style.css';
import './vendor/pace.css';
import 'bootstrap';

function App() {
  return (
    <>
      <Router>
        <Sidemenu />
        <div className="c-wrapper c-fixed-components">
          <Header />
          <div className="c-body">
            <main className="c-main pt-0">
              <div className="container-fluid">
                <div className="fade-in">
                  <Switch>
                    <Route exact path="/">
                      <Dashboard />
                    </Route>
                    <Route exact path="/admin">
                      <Admin />
                    </Route>
                  </Switch>
                </div>
              </div>
            </main>
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;