import axios from 'axios';
import { useEffect, useState } from 'react';
import Meter from '../meter/meter';
import './dashboard.css';

export default function Dashboard() {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const currentMonthIndex = new Date().getMonth(); // Get current month index (0-based)
  const [month, setMonth] = useState(currentMonthIndex + 1); // Adding 1 to make it 1-based
  const [year, setYear] = useState(new Date().getFullYear());
  const [meterData, setMeterData] = useState({ usageData: [] });
  const [yearMonthList, setYearMonthList] = useState([]);

  useEffect(() => {
    fetchYearMonthList();
  }, []);

  useEffect(() => {
    fetchData();
  }, [month, year]);

  const fetchYearMonthList = () => {
    axios.get('https://api.waterusage.in/api/extractMonthAndYear')
      .then(response => {
        setYearMonthList(response.data);
      })
      .catch(error => {
        console.error("Error fetching year and month data: ", error);
      });
  };

  const fetchData = () => {
    const queryParams = month && year ? `?month=${month}&year=${year}` : '';
    axios.get(`https://api.waterusage.in/api/totalUsage${queryParams}`)
      .then((response) => {
        setMeterData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const handleMonthChange = (e) => {
    setMonth(parseInt(e.target.value));
  };

  const handleYearChange = (e) => {
    setYear(parseInt(e.target.value));
    setMonth(''); // Reset month when changing year
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex justify-content-center p-3">
          <h3><strong>Building Usage: </strong>{meterData.usageData.reduce((total, item) => total + item.roomUsage, 0)}L</h3>
        </div>
        <div className="select-container mt-3">
          <select className="select-dropdown mr-3" value={month} onChange={handleMonthChange}>
            <option value="">Select Month</option>
            {yearMonthList.map(yearData => {
              if (yearData.year === year) {
                return yearData.monthList.map((monthName) => (
                  <option key={monthName} value={monthNames.indexOf(monthName) + 1}>{monthName}</option>
                ));
              }
              return null;
            })}
          </select>
          <select className="select-dropdown" value={year} onChange={handleYearChange}>
            <option value="">Select Year</option>
            {yearMonthList.map(yearData => (
              <option key={yearData.year} value={yearData.year}>{yearData.year}</option>
            ))}
          </select>
        </div>
        {meterData && meterData.usageData.length ? (
          <div className="meter-section mt-3">
            <div className="row">
              {meterData.usageData.map(meter => (
                <div className="col-md-4 col-xxl-2 p-2 d-block d-md-flex justify-content-center" key={meter.id}>
                  <Meter data={meter} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="loader"></div>
        )}
      </div>
    </div>
  );
}
