import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faHandHoldingWater } from '@fortawesome/free-solid-svg-icons';
import {
    Link
  } from "react-router-dom";
export default function Sidemenu() {
    return (
        <div className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show" id="sidebar">
            <div className="c-sidebar-brand d-md-down-none justify-content-start pl-3">
                <FontAwesomeIcon icon={faHandHoldingWater} size="lg" /><span className="d-inline-block pl-2 font-lg font-weight-bold">Smart Water Meters</span>
            </div>
            <ul className="c-sidebar-nav">
                <li className="c-sidebar-nav-item">
                    <Link className="c-sidebar-nav-link" path="/" to="/">
                        <FontAwesomeIcon icon={faTachometerAlt} /><span className="d-inline-block pl-2">Dashboard</span>
                    </Link>
                </li>
                {/* <li className="c-sidebar-nav-item">
                    <Link className="c-sidebar-nav-link" path="/admin" to="/admin">
                        <FontAwesomeIcon icon={faUserCircle} /><span className="d-inline-block pl-2">Admin</span>
                    </Link>
                </li> */}
            </ul>
        </div>
    );
}