import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingWater } from '@fortawesome/free-solid-svg-icons';
export default function Header() {
    return (
        <header className="c-header c-header-light c-header-fixed c-header-with-subheader d-flex align-items-center">
            <h4 className="pl-4 p-2 mb-0">Dashboard</h4>
            <a className="c-header-brand d-lg-none pl-3 font-lg font-weight-bold" href="/">
                <FontAwesomeIcon icon={faHandHoldingWater} size="lg" /><span className="d-inline-block pl-3">Smart Water Meters</span>
            </a>
        </header >
    );
}